import { Logo } from "@/website/atoms/Logo/Logo";
import { MainMenu } from "@/website/molecules/MainMenu/MainMenu";
import {
    AppBar,
    Badge,
    Button,
    Container,
    IconButton,
    Link,
    Stack,
} from "@mui/material";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useToggle, useWindowSize } from "usehooks-ts";
import { StyledDrawer } from "./Header.styles";
import { theme } from "@/styles/theme";
import { Close, Menu } from "@mui/icons-material";
import { SiteContext } from "@/contexts/Site/Site.context";
import useQuoteStore from "@/store/quote";
import { useInformationContext } from "@/hooks/useInformationContext/useInformationContext";
import TopBar from "../TopBar";
import { useGetGlobalSettingQuery } from "@/.graphql/cms/graphql";
import { useTranslation } from "react-i18next";

export const Header = () => {
    const navigate = useNavigate();
    const { width = 0 } = useWindowSize();
    const ref = useRef<HTMLDivElement>(null);
    const isMobile = width < theme.breakpoints.values.md;
    const [mobileMenu, toggleMobileMenu] = useToggle();
    const siteContext = useContext(SiteContext);
    const { type: informationType } = useInformationContext();
    const { data: globalData } = useGetGlobalSettingQuery();
    const siteUrl = globalData?.GlobalSetting?.siteUrl;
    const { quantityOfProducts } = useQuoteStore();

    const { t } = useTranslation("translation", {
        keyPrefix: "organisms.header",
    });

    const linkList =
        siteContext.categories
            .map((category) => {
                const { name, slug, id } = category;
                if (!id) return null;
                return {
                    label: name,
                    url: `/products/${slug}`,
                    id: id,
                };
            })
            .filter((item) => item !== null) ?? [];

    const onOfferClick = () => {
        navigate("/quote");
    };

    const menu = <MainMenu list={linkList} />;

    return (
        <>
            {siteUrl ? (
                <TopBar siteLabel={t("topbar.backToSite")} siteUrl={siteUrl} />
            ) : null}
            <AppBar
                position="sticky"
                sx={{
                    zIndex: "1300", // not an magic, Mui drawer has 1200 :-)
                    backgroundColor: theme.palette.background.default,
                }}
                variant="outlined"
                ref={ref}
                elevation={0}
            >
                <Container>
                    <Stack
                        direction="row"
                        spacing={2}
                        py={2.5}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                    >
                        <Stack
                            direction={"row"}
                            spacing={5}
                            alignItems={"center"}
                        >
                            {informationType !== "measurement" ? (
                                <Link
                                    p={0}
                                    height={{ xs: 25, md: 30 }}
                                    width={"auto"}
                                    href="/"
                                >
                                    <Logo />
                                </Link>
                            ) : null}
                            {!isMobile ? menu : null}
                        </Stack>

                        <Stack direction={"row"} spacing={2}>
                            {informationType !== "measurement" ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onOfferClick}
                                    sx={{ position: "relative" }}
                                >
                                    Offerte
                                    {quantityOfProducts() > 0 ? (
                                        <Badge
                                            badgeContent={quantityOfProducts()}
                                            color="error"
                                            sx={{
                                                position: "absolute",
                                                top: 4,
                                                right: 4,
                                                "& .MuiBadge-badge": {
                                                    fontSize: "0.625rem",
                                                    minWidth: "16px",
                                                    height: "16px",
                                                    padding: "0 4px",
                                                },
                                            }}
                                        />
                                    ) : null}
                                </Button>
                            ) : null}
                            {isMobile ? (
                                <IconButton onClick={toggleMobileMenu}>
                                    {mobileMenu ? <Close /> : <Menu />}
                                </IconButton>
                            ) : null}
                        </Stack>
                    </Stack>
                </Container>
            </AppBar>
            {isMobile ? (
                <StyledDrawer
                    open={mobileMenu}
                    menuheight={ref.current?.clientHeight || 0}
                    hideBackdrop
                >
                    {menu}
                </StyledDrawer>
            ) : null}
        </>
    );
};
