import { styled } from "@mui/material";

export const StyledLink = styled("a")(({ theme }) => ({
    color: theme.palette.background.paper,
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "&:hover": {
        textDecoration: "underline",
        color: theme.palette.grey[300],
    },
}));
