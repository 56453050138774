import { create, type StateCreator } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type RequestQuoteState = {
    givenName: string;
    familyName: string;
    emailAddress: string;
    phoneNumber: string;
    streetAddress: string;
    postalCode: string;
    city: string;
    dealId: string;
    message: string;
    pageUri: string;
    isSaving: boolean;
    hubspotUtk: string;
};

type RequestQuoteActions = {
    setField: (field: keyof RequestQuoteState, value: string) => void;
    setSaving: (value: boolean) => void;
    reset: () => void;
};

type State = RequestQuoteState & RequestQuoteActions;

const initialState: RequestQuoteState = {
    givenName: "",
    familyName: "",
    emailAddress: "",
    phoneNumber: "",
    streetAddress: "",
    postalCode: "",
    city: "",
    dealId: "",
    message: "",
    pageUri: "",
    hubspotUtk: "",
    isSaving: false,
};

const requestQuoteStore: StateCreator<State> = (set) => ({
    ...initialState,
    setField: (field, value) => set({ [field]: value }),
    setSaving: (value) => set({ isSaving: value }),
    reset: () => set(initialState),
});

export const QUOTE_STORE_KEY = "kozien/requestQuote";

export const useRequestQuoteStore = create<State>()(
    persist<State>(requestQuoteStore, {
        name: QUOTE_STORE_KEY,
        storage: createJSONStorage(() => localStorage),
    }),
);
