import { Box, Container, Stack, Typography } from "@mui/material";
import { theme } from "@/styles/theme";
import { ArrowBack } from "@mui/icons-material";
import type { FC } from "react";
import type { TopBarProps } from "./TopBar.types";
import { StyledLink } from "./TopBar.styles";
import { useInformationContext } from "@/hooks/useInformationContext/useInformationContext";

export const TopBar: FC<TopBarProps> = ({ siteLabel, siteUrl }) => {
    const { type } = useInformationContext();

    if (type === "measurement") return null;

    return (
        <Box sx={{ bgcolor: theme.palette.anthracite }} data-testid="top-bar">
            <Container sx={{ py: 1 }}>
                <Stack direction="row" gap={2} alignItems="center">
                    <StyledLink
                        href={
                            siteUrl.startsWith("http") ||
                            siteUrl.startsWith("https")
                                ? siteUrl
                                : `https://${siteUrl}`
                        }
                        rel="noopener noreferrer"
                    >
                        <ArrowBack fontSize="small" />
                        <Typography
                            variant="body1"
                            fontSize={14}
                            color="inherit"
                        >
                            {siteLabel}
                        </Typography>
                    </StyledLink>
                </Stack>
            </Container>
        </Box>
    );
};
